import React, { useState } from "react";
import {
  Box,
  Button,
  Grid,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  TextField,
  RadioGroup,
} from "@mui/material";

type TimeProps = {
  onPrev: () => void;
  onNext: (
    lancement: "yesterday" | "amonth" | "trimester" | "nothurry",
    message: string
  ) => void;
};

export default function Time(props: TimeProps) {
  const { onNext, onPrev } = props;

  const [lancement, setLancement] = useState<
    "yesterday" | "amonth" | "trimester" | "nothurry"
  >("amonth");
  const [message, setmessage] = useState("");

  return (
    <Box sx={{ width: "calc(100% - 32px)", p: 2 }}>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12} sm={6} textAlign="center">
          <FormControl
            sx={{ m: 2 }}
            fullWidth
            component="fieldset"
            variant="standard"
          >
            <FormLabel sx={{ textAlign: "left" }} component="legend">
              Délai de lancement du projet :
            </FormLabel>
            <RadioGroup
              aria-labelledby="radio-buttons-group-budget"
              name="radio-buttons-group"
              value={lancement}
              onChange={(e, value) =>
                setLancement(
                  value as "yesterday" | "amonth" | "trimester" | "nothurry"
                )
              }
            >
              <FormControlLabel
                value="yesterday"
                control={<Radio />}
                label="Pour hier"
              />
              <FormControlLabel
                value="amonth"
                control={<Radio />}
                label="D’ici un mois"
              />
              <FormControlLabel
                value="trimester"
                control={<Radio />}
                label="Dans les 3 mois qui viennent"
              />
              <FormControlLabel
                value="nothurry"
                control={<Radio />}
                label="Je ne suis pas pressé"
              />
            </RadioGroup>
            <TextField
              sx={{ mt: 2 }}
              fullWidth
              multiline
              label="Un message ou une information complémentaire
              à nous partager ? "
              value={message}
              onChange={(event) => setmessage(event.target.value)}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Button variant="contained" sx={{ my: 4 }} onClick={() => onPrev()}>
        Précédent
      </Button>
      <Button
        sx={{ float: "right", my: 4 }}
        variant="contained"
        onClick={() => onNext(lancement, message)}
      >
        Suivant
      </Button>
    </Box>
  );
}
