import React, { useState } from "react";
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider, Typography } from "@mui/material";

import Coordonnee from "./steps/Coordonnee";
import Besoin from "./steps/Besoin";
import Time from "./steps/Time";
import Last from "./steps/Last";
import { LinearProgressWithLabel } from "./components/LinearProgressWithLabel";
import { EntrepriseRecherche } from "./schema";

const theme = createTheme({
  palette: {
    primary: {
      main: "#E74C3C",
    },
  },
});

interface Source {
  recommandation: boolean;
  linkedin: boolean;
  google: boolean;
  evenements: boolean;
  formations: boolean;
  voyance: boolean;
  other?: string;
}
interface AppState {
  firstname?: string;
  lastname?: string;
  email?: string;
  phone?: string;
  entreprise?: EntrepriseRecherche;
  inprogress?: boolean;
  activityarea?: "btob" | "btoc" | "both";
  need?: "regulary" | "punctual";
  offer?: [boolean, boolean, boolean, boolean, string];
  budget?:
    | "250to500"
    | "500to750"
    | "500to1000"
    | "750to1000"
    | "1000to2500"
    | "2500to5000"
    | "5000to10000"
    | "nolimit";
  start?: "yesterday" | "amonth" | "trimester" | "nothurry";
  message?: string;
  referal?: [boolean, boolean, boolean, boolean, boolean, boolean, string];
}

function App() {
  const [step, setStep] = useState<25 | 50 | 75 | 100>(25);
  const [state, setState] = useState<AppState>({});

  const onCoordonnee = (
    firstname: string,
    lastname: string,
    email: string,
    phone: string,
    activityarea: "btoc" | "btob" | "both",
    entreprise: EntrepriseRecherche,
    inprogress: boolean
  ) => {
    setStep(50);
    setState({
      ...state,
      firstname,
      lastname,
      email,
      phone,
      activityarea,
      entreprise,
      inprogress,
    });
  };

  const onBesoin = (
    need: "regulary" | "punctual",
    offer: [boolean, boolean, boolean, boolean, string],
    budget:
      | "250to500"
      | "500to750"
      | "500to1000"
      | "750to1000"
      | "1000to2500"
      | "2500to5000"
      | "5000to10000"
      | "nolimit"
  ) => {
    setStep(75);
    setState({
      ...state,
      need,
      offer,
      budget,
    });
  };

  const onTime = (
    lancement: "yesterday" | "amonth" | "trimester" | "nothurry",
    message: string
  ) => {
    setStep(100);
    setState({
      ...state,
      start: lancement,
      message,
    });
  };

  const onLast = (source: Source) => {
    const referal: [
      boolean,
      boolean,
      boolean,
      boolean,
      boolean,
      boolean,
      string
    ] = [
      source.recommandation,
      source.linkedin,
      source.google,
      source.evenements,
      source.formations,
      source.voyance,
      source.other ?? "",
    ];
    const newState = {
      ...state,
      referal,
    };
    setState(newState);
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/submit`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        firstname: newState.firstname,
        lastname: newState.lastname,
        email: newState.email,
        phone: newState.phone,
        inprogress: newState.inprogress,
        activityarea: newState.activityarea,
        need: newState.need,
        offer: newState.offer,
        budget: newState.budget,
        start: newState.start,
        message: newState.message,
        referal: newState.referal,
        entreprise: {
          nom_entreprise: newState.entreprise?.nom_entreprise,
          adresse_ligne_1: newState.entreprise?.siege?.adresse_ligne_1,
          code_postal: newState.entreprise?.siege?.code_postal,
          ville: newState.entreprise?.siege?.ville,
          forme_juridique: newState.entreprise?.forme_juridique,
          siret: newState.entreprise?.siege?.siret,
        },
      }),
    }).then(() => {
      window.location.href = 'https://bonjour.socialmediafamily.fr/merci';
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Box sx={{ width: "calc(100% - 32px)", p: 2 }}>
          <Typography align="left">
            {step === 25
              ? "Vos coordonnées"
              : step === 50
              ? "Quel est votre besoin ?"
              : step === 75
              ? "D’ici combien de temps ?"
              : "Une dernière question"}
          </Typography>
          <LinearProgressWithLabel variant="determinate" value={step} />
        </Box>
        {step === 25 ? (
          <Coordonnee onNext={onCoordonnee} />
        ) : step === 50 ? (
          <Besoin onNext={onBesoin} onPrev={() => setStep(25)} />
        ) : step === 75 ? (
          <Time onNext={onTime} onPrev={() => setStep(50)} />
        ) : (
          <Last onNext={onLast} onPrev={() => setStep(75)} />
        )}
      </div>
    </ThemeProvider>
  );
}

export default App;
