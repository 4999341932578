import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Grid,
  TextField,
  Box,
  Divider,
  Autocomplete,
  FormGroup,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import MuiPhoneNumber from "material-ui-phone-number";
// import throttle from "lodash.throttle";
import debounce from "lodash.debounce";
import { EntrepriseRecherche } from "../schema";

type CoordonneeProps = {
  onNext: (
    firstname: string,
    lastname: string,
    email: string,
    phone: string,
    secteur: "btoc" | "btob" | "both",
    entreprise: EntrepriseRecherche,
    inprogress: boolean
  ) => void;
};

export default function Coordonnee(props: CoordonneeProps) {
  const { onNext } = props;

  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [secteur, setsecteur] = useState<"btoc" | "btob" | "both">("btoc");
  const [inprogress, setInprogress] = useState(false);
  const [searchError, setSearchError] = useState(false);

  const [entreprise, setEntreprise] = useState<EntrepriseRecherche | undefined>(
    undefined
  );
  const [entrepriseName, setEntrepriseName] = useState<string | undefined>(
    undefined
  );
  const [entrepriseAddress, setEntrepriseAddress] = useState<
    string | undefined
  >(undefined);
  const [entrepriseCodePostal, setEntrepriseCodePostal] = useState<
    string | undefined
  >(undefined);
  const [entrepriseVille, setEntrepriseVille] = useState<string | undefined>(
    undefined
  );
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState<readonly EntrepriseRecherche[]>([]);
  const [searching, setsearching] = useState(false);

  const handleNext = () => {
    if (entreprise !== undefined) {
      onNext(
        firstname,
        lastname,
        email,
        phone,
        secteur,
        entreprise,
        inprogress
      );
    } else if (entrepriseName !== undefined) {
      onNext(
        firstname,
        lastname,
        email,
        phone,
        secteur,
        {
          nom_entreprise: entrepriseName,
          siege: {
            adresse_ligne_1: entrepriseAddress,
            code_postal: entrepriseCodePostal,
            ville: entrepriseVille,
          },
        },
        inprogress
      );
    }
  };

  const smfService = useMemo(
    () =>
      debounce(
        (
          request: { input: string },
          callback: (results: EntrepriseRecherche[]) => void
        ) => {
          fetch(`${process.env.REACT_APP_API_ENDPOINT}/search`, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ q: request.input }),
          })
            .then((response) => response.json())
            .then(callback);
        },
        1000
      ),
    []
  );

  useEffect(() => {
    if (inputValue === "") {
      setOptions(entreprise ? [entreprise] : []);
      return undefined;
    }

    if (inputValue.length > 3) {
      setsearching(true);
      smfService(
        { input: inputValue },
        (results: EntrepriseRecherche[] | { error: string }) => {
          console.log({ results });
          if ("error" in results) {
            setInprogress(true);
            setSearchError(true);
          } else {
            let newOptions: EntrepriseRecherche[] = [];
            if (entreprise) {
              newOptions = [entreprise];
            }
            if (results) {
              newOptions = [...newOptions, ...results];
            }
            setOptions(newOptions);
            setsearching(false);
          }
        }
      );
    }
  }, [entreprise, inputValue, smfService]);

  const submitDisabled = useMemo(() => {
    if (inprogress) {
      return (
        firstname.trim().length === 0 ||
        lastname.trim().length === 0 ||
        email.trim().length === 0 ||
        phone.trim().length === 0 ||
        (entrepriseName ?? '').trim().length === 0 ||
        (entrepriseAddress ?? '').trim().length === 0 ||
        (entrepriseCodePostal ?? '').trim().length === 0 ||
        (entrepriseVille ?? '').trim().length === 0
      );
    }
    if (entreprise === undefined) {
      return true;
    }
    return (
      firstname.trim().length === 0 ||
      lastname.trim().length === 0 ||
      email.trim().length === 0 ||
      phone.trim().length === 0
    );
  }, [
    inprogress,
    firstname,
    lastname,
    email,
    phone,
    entreprise,
    entrepriseName,
    entrepriseAddress,
    entrepriseCodePostal,
    entrepriseVille,
  ]);

  return (
    <Box sx={{ width: "calc(100% - 32px)", p: 2 }}>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12} sm={6}>
          <Stack spacing={2}>
            <TextField
              required
              fullWidth
              label="Prénom"
              placeholder="Prénom"
              value={firstname}
              onChange={(event) => setfirstname(event.target.value)}
            />
            <TextField
              required
              fullWidth
              label="Nom"
              placeholder="Nom"
              value={lastname}
              onChange={(event) => setlastname(event.target.value)}
            />
            <TextField
              required
              fullWidth
              label="Email"
              type="email"
              value={email}
              onChange={(event) => setemail(event.target.value)}
            />
            <MuiPhoneNumber
              required
              fullWidth
              variant="outlined"
              defaultCountry="fr"
              regions={"europe"}
              value={phone}
              onChange={(event) => setphone(event.toString())}
            />
          </Stack>
          <Divider variant="middle" flexItem sx={{ my: 4 }} />
        </Grid>
      </Grid>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12} sm={6}>
          <Stack spacing={2}>
            {inprogress === false && (
              <Autocomplete
                fullWidth
                getOptionLabel={(option) =>
                  option.nom ?? option.nom_entreprise ?? ""
                }
                filterOptions={(x) => x}
                options={options}
                autoComplete
                includeInputInList
                loading={searching}
                filterSelectedOptions
                value={entreprise}
                onChange={(
                  event: any,
                  newValue: EntrepriseRecherche | null
                ) => {
                  setOptions(newValue ? [newValue, ...options] : options);
                  setEntreprise(newValue || undefined);
                }}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Entreprise" fullWidth />
                )}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.siren ?? ""}>
                      <Grid container alignItems="center">
                        <Grid item xs>
                          {option.nom_entreprise ?? ""} (
                          {option.siege?.code_postal})
                          <Typography variant="body2" color="text.secondary">
                            {option.siren_formate ?? ""} - Création:{" "}
                            {option.date_creation_formate ?? ""} -{" "}
                            {option.forme_juridique}
                          </Typography>
                        </Grid>
                      </Grid>
                    </li>
                  );
                }}
              />
            )}
            <FormGroup>
              <FormControlLabel
                control={<Checkbox />}
                label="Entreprise en cours de création"
                checked={searchError || inprogress}
                onChange={(e, b) => setInprogress(b)}
              />
              {searchError && (
                <FormHelperText error={searchError}>
                  Nous avons eu trop de demandes, nous ne pouvons pas rechercher
                  votre entreprise.
                </FormHelperText>
              )}
            </FormGroup>
            {inprogress && (
              <>
                <TextField
                  required
                  fullWidth
                  label="Nom d'entreprise"
                  placeholder="Nom d'entreprise"
                  value={entrepriseName}
                  onChange={(event) => setEntrepriseName(event.target.value)}
                />
                <TextField
                  required
                  fullWidth
                  label="Adresse"
                  placeholder="Adresse"
                  value={entrepriseAddress}
                  onChange={(event) => setEntrepriseAddress(event.target.value)}
                />
                <TextField
                  required
                  fullWidth
                  label="Code Postal"
                  placeholder="Code Postal"
                  value={entrepriseCodePostal}
                  onChange={(event) =>
                    setEntrepriseCodePostal(event.target.value)
                  }
                />
                <TextField
                  required
                  fullWidth
                  label="Ville"
                  placeholder="Ville"
                  value={entrepriseVille}
                  onChange={(event) => setEntrepriseVille(event.target.value)}
                />
              </>
            )}
            <FormControl>
              <FormLabel id="secteur-group-label" sx={{ textAlign: "left" }}>
                Secteur d’activité :
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="secteur-group-label"
                name="row-radio-buttons-group"
                value={secteur}
                onChange={(e, value) =>
                  setsecteur(value as "btoc" | "btob" | "both")
                }
              >
                <FormControlLabel
                  value="btob"
                  control={<Radio />}
                  label="BtoB"
                />
                <FormControlLabel
                  value="btoc"
                  control={<Radio />}
                  label="BtoC"
                />
                <FormControlLabel
                  value="both"
                  control={<Radio />}
                  label="Les deux"
                />
              </RadioGroup>
            </FormControl>
          </Stack>
        </Grid>
      </Grid>
      <Button
        variant="contained"
        sx={{ float: "right", my: 4 }}
        disabled={submitDisabled}
        onClick={handleNext}
      >
        Suivant
      </Button>
    </Box>
  );
}
