import React, { useMemo, useState } from "react";
import {
  AppBar,
  Grid,
  Button,
  Tab,
  Tabs,
  TabProps,
  TabsProps,
  styled,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
  RadioGroup,
  Radio,
  Box,
} from "@mui/material";
import { FormControlLabelWithInput } from "../components/FormControlLabelWithInput";

const StyledTabs = styled((props: TabsProps) => <Tabs {...props} />)(
  ({ theme }) => ({
    "& .MuiTabs-indicator": {
      display: "none",
    },
  })
);

const StyledTab = styled((props: TabProps) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    "&.Mui-selected": {
      color: "#fff",
      backgroundColor: theme.palette.primary.main,
    },
  })
);

type BesoinProps = {
  onPrev: () => void;
  onNext: (
    need: "regulary" | "punctual",
    offer: [boolean, boolean, boolean, boolean, string],
    budget:
      | "250to500"
      | "500to750"
      | "500to1000"
      | "750to1000"
      | "1000to2500"
      | "2500to5000"
      | "5000to10000"
      | "nolimit"
  ) => void;
};

export default function Besoin(props: BesoinProps) {
  const { onNext, onPrev } = props;
  const [tabValue, setTabValue] = useState(0);
  const [optionMensuelle, setOptionMensuelle] = useState(true);
  const [optionCreaReguliere, setOptionCreaReguliere] = useState(false);
  const [optionCampagne, setOptionCampagne] = useState(false);
  const [optionAccompagnementRegulier, setOptionAccompagnementRegulier] =
    useState(false);
  const [optionOther, setOptionOther] = useState<string | undefined>();

  const [radioBudget, setRadioBudget] = useState<
    "250to500" | "500to750" | "750to1000" | "1000to2500" | "nolimit"
  >("750to1000");

  const [optionAnimationEvent, setOptionAnimationEvent] = useState(true);
  const [optionCreaPonctuelle, setOptionCreaPonctuelle] = useState(false);
  const [optionCampagne2, setOptionCampagne2] = useState(false);
  const [optionFormation, setOptionFormation] = useState(false);
  const [optionOther2, setOptionOther2] = useState<string | undefined>();
  const [radioBudget2, setRadioBudget2] = useState<
    "500to1000" | "1000to2500" | "2500to5000" | "5000to10000" | "nolimit"
  >("2500to5000");

  const nextDisabled = useMemo(() => {
    if (tabValue === 0) {
      return (
        optionMensuelle === false &&
        optionCreaReguliere === false &&
        optionCampagne === false &&
        optionAccompagnementRegulier === false &&
        (optionOther === undefined || optionOther.trim().length === 0)
      );
    } else {
      return (
        optionAnimationEvent === false &&
        optionCreaPonctuelle === false &&
        optionCampagne2 === false &&
        optionFormation === false &&
        (optionOther2 === undefined || optionOther2.trim().length === 0)
      );
    }
  }, [
    optionAccompagnementRegulier,
    optionAnimationEvent,
    optionCampagne,
    optionCampagne2,
    optionCreaPonctuelle,
    optionCreaReguliere,
    optionFormation,
    optionMensuelle,
    optionOther,
    optionOther2,
    tabValue,
  ]);

  const onSubmit = () => {
    onNext(
      tabValue === 0 ? "punctual" : "regulary",
      tabValue === 0
        ? [
            optionMensuelle,
            optionCreaReguliere,
            optionCampagne,
            optionAccompagnementRegulier,
            optionOther ?? "",
          ]
        : [
            optionAnimationEvent,
            optionCreaPonctuelle,
            optionCampagne2,
            optionFormation,
            optionOther2 ?? "",
          ],
      tabValue === 0 ? radioBudget : radioBudget2
    );
  };

  return (
    <Box sx={{ width: "calc(100% - 32px)", p: 2 }}>
      <AppBar position="static" color="inherit">
        <StyledTabs
          value={tabValue}
          onChange={(event, newValue) => setTabValue(newValue)}
          variant="fullWidth"
          textColor="inherit"
        >
          <StyledTab label="Prestation régulière" />
          <StyledTab label="Prestation ponctuelle" />
        </StyledTabs>
      </AppBar>
      <Grid container spacing={2} sx={{ my: 2 }}>
        <Grid
          item
          xs={12}
          sm={6}
          sx={
            tabValue === 1
              ? { opacity: 0.7, my: 2, display: { xs: "none", sm: "block" } }
              : { my: 2 }
          }
        >
          <FormControl
            fullWidth
            disabled={tabValue === 1}
            component="fieldset"
            variant="standard"
          >
            <FormLabel component="legend">
              Les offres qui vous intéressent :<br />
              <small>(Choix multiples possibles)</small>
            </FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={optionMensuelle}
                    onChange={(e, o) => setOptionMensuelle(o)}
                  />
                }
                label="Une gestion mensuelle de mes réseaux sociaux avec des publications régulières"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={optionCreaReguliere}
                    onChange={(e, o) => setOptionCreaReguliere(o)}
                  />
                }
                label="De la création régulière de contenus (photos, vidéos, visuels, illustrations, motion design, etc)"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={optionCampagne}
                    onChange={(e, o) => setOptionCampagne(o)}
                  />
                }
                label="Des campagnes publicitaires régulières pour vendre ou faire connaitre mes produits ou services"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={optionAccompagnementRegulier}
                    onChange={(e, o) => setOptionAccompagnementRegulier(o)}
                  />
                }
                label="Un accompagnement régulier pour gérer mes réseaux sociaux en autonomie et répondre à mes questions"
              />
              <FormControlLabelWithInput
                control={
                  <Checkbox
                    checked={optionOther !== undefined}
                    onChange={(e, o) => setOptionOther(o ? "" : undefined)}
                  />
                }
                label={
                  <TextField
                    disabled={optionOther === undefined}
                    fullWidth
                    multiline
                    label="Autre"
                    value={optionOther ?? ""}
                    onChange={(e) => setOptionOther(e.target.value)}
                  />
                }
              />
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={
            tabValue === 0
              ? { opacity: 0.7, my: 2, display: { xs: "none", sm: "block" } }
              : { my: 2 }
          }
        >
          <FormControl
            fullWidth
            disabled={tabValue === 0}
            component="fieldset"
            variant="standard"
          >
            <FormLabel component="legend">
              Les offres qui vous intéressent :<br />
              <small>(Choix multiples possibles)</small>
            </FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={optionAnimationEvent}
                    onChange={(e, o) => setOptionAnimationEvent(o)}
                  />
                }
                label="L’animation de mes réseaux sociaux pour un événement ou pour un lancement de produits/services"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={optionCreaPonctuelle}
                    onChange={(e, o) => setOptionCreaPonctuelle(o)}
                  />
                }
                label="De la création ponctuelle de contenus (photos, vidéos, visuels, illustrations, motion design, etc)"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={optionCampagne2}
                    onChange={(e, o) => setOptionCampagne2(o)}
                  />
                }
                label="Des campagnes publicitaires régulières pour vendre ou faire connaitre mes produits ou services"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={optionFormation}
                    onChange={(e, o) => setOptionFormation(o)}
                  />
                }
                label="Une formation pour apprendre à gérer mes réseaux sociaux en autonomie"
              />
              <FormControlLabelWithInput
                control={
                  <Checkbox
                    checked={optionOther2 !== undefined}
                    onChange={(e, o) => setOptionOther2(o ? "" : undefined)}
                  />
                }
                label={
                  <TextField
                    disabled={optionOther2 === undefined}
                    fullWidth
                    multiline
                    label="Autre"
                    value={optionOther2 ?? ""}
                    onChange={(e) => setOptionOther2(e.target.value)}
                  />
                }
              />
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={
            tabValue === 1
              ? { opacity: 0.7, my: 2, display: { xs: "none", sm: "block" } }
              : { my: 2 }
          }
        >
          <FormControl
            fullWidth
            disabled={tabValue === 1}
            component="fieldset"
            variant="standard"
          >
            <FormLabel id="radio-buttons-group-budget">
              Votre budget :
            </FormLabel>
            <RadioGroup
              aria-labelledby="radio-buttons-group-budget"
              name="radio-buttons-group"
              value={radioBudget}
              onChange={(e, value) =>
                setRadioBudget(
                  value as
                    | "250to500"
                    | "500to750"
                    | "750to1000"
                    | "1000to2500"
                    | "nolimit"
                )
              }
            >
              <FormControlLabel
                value="250to500"
                control={<Radio />}
                label="Entre 250€ et 500€ par mois"
              />
              <FormControlLabel
                value="500to750"
                control={<Radio />}
                label="Entre 500€ et 750€ par mois"
              />
              <FormControlLabel
                value="750to1000"
                control={<Radio />}
                label="Entre 750€ et 1.000€ par mois"
              />
              <FormControlLabel
                value="1000to2500"
                control={<Radio />}
                label="Entre 1.000€ et 2.500€ par mois"
              />
              <FormControlLabel
                value="nolimit"
                control={<Radio />}
                label="Je n’ai pas de limite, je suis Elon Musk"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={
            tabValue === 0
              ? { opacity: 0.7, my: 2, display: { xs: "none", sm: "block" } }
              : { my: 2 }
          }
        >
          <FormControl
            fullWidth
            disabled={tabValue === 0}
            component="fieldset"
            variant="standard"
          >
            <FormLabel id="radio-buttons-group-budget">
              Votre budget :
            </FormLabel>
            <RadioGroup
              aria-labelledby="radio-buttons-group-budget"
              name="radio-buttons-group"
              value={radioBudget2}
              onChange={(e, value) =>
                setRadioBudget2(
                  value as
                    | "500to1000"
                    | "1000to2500"
                    | "2500to5000"
                    | "5000to10000"
                    | "nolimit"
                )
              }
            >
              <FormControlLabel
                value="500to1000"
                control={<Radio />}
                label="Entre 500€ et 1.000€"
              />
              <FormControlLabel
                value="1000to2500"
                control={<Radio />}
                label="Entre 1.000€ et 2.500€"
              />
              <FormControlLabel
                value="2500to5000"
                control={<Radio />}
                label="Entre 2.500€ et 5.000€"
              />
              <FormControlLabel
                value="5000to10000"
                control={<Radio />}
                label="Entre 5.000€ et 10.000€"
              />
              <FormControlLabel
                value="nolimit"
                control={<Radio />}
                label="Je n’ai pas de limite, je suis Elon Musk"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      <Button variant="contained" sx={{ my: 4 }} onClick={() => onPrev()}>
        Précédent
      </Button>
      <Button
        variant="contained"
        sx={{ float: "right", my: 4  }}
        disabled={nextDisabled}
        onClick={onSubmit}
      >
        Suivant
      </Button>
    </Box>
  );
}
