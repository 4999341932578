import {
  styled,
  FormControlLabel,
  FormControlLabelProps,
} from "@mui/material";

export const FormControlLabelWithInput = styled((props: FormControlLabelProps) => <FormControlLabel {...props} />)(() => ({
  "& .MuiFormControlLabel-label": {
    width: '100%',
  },
}));