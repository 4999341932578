import React, { useState } from "react";
import {
  Box,
  Grid,
  Button,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
  CircularProgress,
} from "@mui/material";
import { FormControlLabelWithInput } from "../components/FormControlLabelWithInput";

type Source = {
  recommandation: boolean;
  linkedin: boolean;
  google: boolean;
  evenements: boolean;
  formations: boolean;
  voyance: boolean;
  other?: string;
};

type LastProps = {
  onPrev: () => void;
  onNext: (source: Source) => void;
};

export default function Last(props: LastProps) {
  const { onNext, onPrev } = props;

  const [accept, setAccept] = useState(false);
  const [sending, setSending] = useState(false);
  const [state, setState] = useState<Source>({
    evenements: false,
    formations: false,
    google: false,
    linkedin: false,
    recommandation: false,
    voyance: true,
  });

  const {
    recommandation,
    linkedin,
    google,
    evenements,
    formations,
    voyance,
    other,
  } = state;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === "other") {
      setState({
        ...state,
        other: event.target.checked === false ? undefined : "",
      });
    } else {
      setState({
        ...state,
        [event.target.name]: event.target.checked,
      });
    }
  };

  return (
    <Box sx={{ width: "calc(100% - 32px)", p: 2 }}>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormControl
            sx={{ m: 2 }}
            fullWidth
            component="fieldset"
            variant="standard"
          >
            <FormLabel component="legend">
              Comment avez-vous connu la Social Media Family ?<br />
              <small>(Choix multiples possibles)</small>
            </FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={recommandation}
                    name="recommandation"
                    onChange={handleChange}
                  />
                }
                label="Recommandation"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={linkedin}
                    name="linkedin"
                    onChange={handleChange}
                  />
                }
                label="Linkedin"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={google}
                    name="google"
                    onChange={handleChange}
                  />
                }
                label="Google"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={evenements}
                    name="evenements"
                    onChange={handleChange}
                  />
                }
                label="Événements"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formations}
                    name="formations"
                    onChange={handleChange}
                  />
                }
                label="Formations"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={voyance}
                    name="voyance"
                    onChange={handleChange}
                  />
                }
                label="Voyance"
              />
              <FormControlLabelWithInput
                control={
                  <Checkbox
                    checked={other !== undefined}
                    name="other"
                    onChange={handleChange}
                  />
                }
                label={
                  <TextField
                    fullWidth
                    disabled={other === undefined}
                    label="Autre"
                    value={other}
                    onChange={(event) =>
                      setState({ ...state, other: event.target.value })
                    }
                  />
                }
              />
            </FormGroup>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} sm={6}>
          <FormControlLabel
            value={accept}
            onChange={(e, checked) => setAccept(checked)}
            control={<Checkbox />}
            label="J’autorise la Social Media Family à utiliser et stocker ces données pour me recontacter. Et je leur fais confiance pour ne pas les vendre à d’autres entreprises ou à la CIA."
          />
        </Grid>
      </Grid>
      <Button variant="contained" sx={{ my: 4 }} onClick={() => onPrev()}>
        Précédent
      </Button>
      <Button
        sx={{ float: "right", my: 4 }}
        disabled={accept === false || sending}
        variant="contained"
        onClick={() => {
          setSending(true);
          onNext(state);
        }}
      >
        {sending ? <CircularProgress /> : 'Envoyer'}
      </Button>
    </Box>
  );
}
